import React, { useState, useEffect } from "react";

import styled from "styled-components";
import Logo from "../asserts/images/log.png";
import { Helmet } from "react-helmet";
import { RiMenu3Line } from "react-icons/ri";
import { WebSiteContext } from "../app.v2.js";
import MenuButton from "./menuButton";
import { BarContainer, Container } from "../styles/styleComponent";
import { useNavigate } from "react-router";

const List = styled.ul`
  list-style: none;
  float: right;
  font-weight: bold;
  margin-top: 15px;
  posiyion: absolute;
  z-index: 5 !important;
`;

const Item = styled.li`
  list-style: none;
  display: ${(props) => (props.mobile ? "none" : "inline")};
  margin: 0px;
  padding: 10px 0px 10px 25px;
  cursor: pointer;
  font-size: 16px;
  z-index: 5 !important;
  @media (max-width: 768px) {
    display: ${(props) => (props.mobile ? "inline" : "none")};
  }
`;
const Nav = styled.nav`
  color: white;
  padding: 0px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  transition: 0.4s;

  z-index: 3;
  padding: 10px 0px;
`;
const Div = styled.div`
  display: inline-block;
  color: white;
  padding: 0px;
`;
const Img = styled.img`
  width: 50px;
`;

export default function Navbar() {
  const navigate = useNavigate();
  const {
    state: { menu_is_open },
  } = React.useContext(WebSiteContext);

  const [scroll, setScroll] = useState(0);
  const [lastScroll, setLastScroll] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.pageYOffset);
    });
  }, []);

  useEffect(() => {
    if (scroll > lastScroll && scroll > 50) {
      document.getElementById("nav").style.top = "-80px";
      document.getElementById("nav").style.backgroundColor = "#0a192f";
    } else {
      document.getElementById("nav").style.top = "0";
    }
    if (scroll < 100) {
      document.getElementById("nav").style.backgroundColor = "transparent";
    } else {
      document.getElementById("nav").style.backgroundColor = "#0a192f";
    }
    setLastScroll(scroll);
  }, [scroll]);

  const scrollToElement = (id) => {
    if (id === "contact") {
      const element = document.getElementById(id);
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      navigate("/");
      setTimeout(() => {
        if (id === "home") {
          window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
          const element = document.getElementById(id);
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 900);
    }
  };

  return (
    <>
      <Nav id="nav">
        <BarContainer>
          <Div>
            <Img src={Logo} alt="Logo ismail hocine" />
          </Div>
          <List>
            <Item onClick={() => scrollToElement("home")}>Home</Item>
            <Item onClick={() => scrollToElement("about")}>About</Item>
            <Item onClick={() => scrollToElement("articles")}>Articles</Item>
            <Item onClick={() => scrollToElement("projects")}>Projects</Item>
            <Item onClick={() => scrollToElement("contact")}>Contact</Item>
          </List>
        </BarContainer>
      </Nav>
    </>
  );
}
