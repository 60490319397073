import React from "react";

import { BiLinkExternal } from "react-icons/bi";
import { FiGithub } from "react-icons/fi";
import styled from "styled-components";
import LazyImage from "./LazyImage";
import MobImg from "./mobImg";
import { WebSiteContext } from "../../../app.v2";
import { Link } from "react-router-dom";
const ProjectCardContainer = styled.div`
  box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 0.1);
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 0.2);
  }
  position: relative;
  margin-top: 50px;
  padding: 20px;

  border-radius: 10px;
  position: relative;
`;
const Paragraph = styled.p`
  border-radius: 6px;
  background-color: #112240;

  padding: 20px;
  font-size: 18px;
  color: #bcc8ed;
  @media only screen and (max-width: 992px) {
    background-color: transparent;

    padding: 0px;
  }
`;
const PorjectContainer = styled.p`
  direction: ${(props) => props.dir};
  @media only screen and (max-width: 992px) {
    direction: ltr;
  }
`;
export default function FeaturedProject({
  name,
  description,
  tech,
  index,
  external,
  github,
  img,
  openPage,
  titleLink,
  images,
  id,
  fullDescription,
}) {
  const { dispatch } = React.useContext(WebSiteContext);
  const handleOpenModal = () => {
    if (images) {
      dispatch({
        type: "OPEN_MODAL",
        payload: {
          images: images,
          title: name,
          describition: fullDescription ? fullDescription : description,
          tags: tech,
        },
      });
    }
  };
  return (
    <ProjectCardContainer
      data-aos="fade-up"
      data-aos-once
      data-aos-duration="1400"
    >
      <PorjectContainer
        dir={index % 2 ? "rtl" : "lft"}
        className="row-featured-project"
      >
        <div
          className="mobile-only "
          // style={{ float: index % 2 ? "left" : "right" }}
        >
          {images ? (
            <div onClick={handleOpenModal} style={{ cursor: "pointer" }}>
              <MobImg alt={`image project of ${name}`} src={img} />
            </div>
          ) : (
            <a
              href={external}
              aria-label="External Link"
              className="external"
              target="_blank"
              rel="noreferrer"
              style={{
                color: "white",
                display: "inline",
                width: "100%",
              }}
            >
              <MobImg alt={`image project of ${name}`} src={img} />
            </a>
          )}
        </div>
        <div
          className="desktop project-img"
          style={{ float: index % 2 ? "left" : "right" }}
        >
          <a
            href={external}
            aria-label="External Link"
            className="external"
            target="_blank"
            rel="noreferrer"
            style={{
              color: "white",

              display: "inline",
              width: "100%",
            }}
          >
            <LazyImage alt={`image project of ${name}`} src={img} />
          </a>
        </div>
        <div style={{ color: "white", textAlign: "left" }}>
          {!titleLink ? (
            <h2 style={{ fontWeight: "bold" }}>{name}</h2>
          ) : (
            <a
              href={external}
              aria-label="External Link"
              className="external"
              target="_blank"
              rel="noreferrer"
              style={{ color: "white" }}
            >
              <h2 style={{ fontWeight: "bold" }}>{name}</h2>
            </a>
          )}
          <Paragraph>
            {description}
            {/* {description.length > 100
              ? `${description.substring(0, 100)} ...`
              : description} */}
          </Paragraph>
          {openPage && (
            <ButtonSection style={{ paddingTop: "230px 0" }}>
              <Button to={`/${id}`}>Show More</Button>
              <br />
            </ButtonSection>
          )}
          <ul className="project-tags">
            {tech.map((item, index) => (
              <li key={`featured-tag` + index}>{item}</li>
            ))}
          </ul>
          <div className="project-top">
            <div
              className="project-links"
              style={{
                color: "white",
                marginTop: "30px",
                display: "inline-block",
              }}
            >
              {github && (
                <a
                  href={github}
                  aria-label="GitHub Link"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: "white",
                    marginRight: "20px",
                    float: "left",
                    display: "inline",
                  }}
                >
                  <FiGithub size={24} />
                </a>
              )}
              {external && (
                <a
                  href={external}
                  aria-label="External Link"
                  className="external"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: "white",
                    marginRight: "20px",
                    float: "left",
                    display: "inline",
                  }}
                >
                  <BiLinkExternal size={24} />
                </a>
              )}
            </div>
          </div>
        </div>
      </PorjectContainer>
      {/* <div
        className="row-featured-project"
        style={{
          direction: index % 2 ? "rtl" : "lft",
        }}
        data-aos="fade-up"
        data-aos-once
        data-aos-duration="1400"
      ></div> */}
    </ProjectCardContainer>
  );
}

const ButtonSection = styled.div`
  display: flex;

  padding: 20px 0;
  justify-content: center;
  align-items: center;
`;
const Button = styled(Link)`
  color: black;
  width: 200px; /* updated the width */
  font-size: clamp(7px, 3vw, 15px);
  font-weight: 600;
  background-color: #62ecd0;
  padding: 10px 30px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
`;
