import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc, getFirestore } from "firebase/firestore"; // Import Firestore methods
import { app } from "../firebase/firebase";
import { Container } from "../styles/styleComponent";
import WorkViewer from "../components/slide";
import styled from "styled-components";
export default function Project() {
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const db = getFirestore(app);
  useEffect(() => {
    const fetchProject = async () => {
      try {
        const docRef = doc(db, "projects", id); // Reference the document in the "projects" collection
        const docSnap = await getDoc(docRef); // Fetch the document

        if (docSnap.exists()) {
          setProject(docSnap.data()); // Set the project data
        } else {
          console.error("No such document!");
          setProject(null); // Handle missing document
        }
      } catch (error) {
        console.error("Error fetching project:", error);
      } finally {
        setLoading(false); // Stop the loading spinner
      }
    };

    fetchProject();
  }, [id]); // Re-run the effect if `id` changes

  if (loading) {
    return <div>Loading...</div>; // Loading state
  }

  if (!project) {
    return <div>Project not found.</div>; // Not found state
  }

  return (
    <Container style={{ marginTop: "90px" }}>
      <div style={{ backgroundColor: "#0a192f", color: "white" }}>
        <h1 style={{ fontWeight: "bold", margin: "70px 0px" }}>
          {project.name}
        </h1>
        <WorkViewer images={project?.images} />
        <Paragraph
          dangerouslySetInnerHTML={{
            __html: project.description || "<p>No description available.</p>",
          }}
        ></Paragraph>
      </div>
    </Container>
  );
}

const Paragraph = styled.p`
  border-radius: 12px;
  background-color: #112240;
  margin: 100px 0px;
  padding: 20px;
  font-size: 18px;
  color: #bcc8ed;
  @media only screen and (max-width: 992px) {
    background-color: transparent;

    padding: 0px;
  }
`;
