import React from "react";
import Section from "../section";
import FeaturedProject from "./projects/featuredProject";
import styled from "styled-components";
import {
  getFirestore,
  collection,
  query,
  limit,
  getDocs,
  where,
} from "firebase/firestore";
import { app } from "../../firebase/firebase";
// import editorCode from "../../asserts/images/newImg/4.jpg";
// import CityExplor from "../../asserts/images/newImg/map 2.jpg";
// import Task from "../../asserts/images/newImg/task.jpg";
// import Quiz from "../../asserts/images/newImg/quiz.jpg";
// import Music from "../../asserts/images/newImg/music.jpg";
// import WaslaImg from "../../asserts/images/newImg/wasla.jpg";

export default function Projects() {
  const [data, setData] = React.useState([]);
  const db = getFirestore(app);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const getLatestProjects = async () => {
    const projectsCollection = collection(db, "projects");
    const q = query(
      projectsCollection,
      where("highlighted", "==", false),
      // orderBy("createdAt", "desc"), // Sort by createdAt field in descending order
      limit(100) // Limit the results to the latest 100
    );

    const querySnapshot = await getDocs(q);
    const projects = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return projects;
  };

  React.useEffect(() => {
    getLatestProjects()
      .then((projects) => {
        setData(projects);
        setIsLoading(false);
        console.log(projects);
      })
      .catch((error) => {
        setError(error.message);
        setIsLoading(false);
      });
  }, []);
  return (
    <Section name="Other Projects">
      {isLoading && <>loading</>}
      {error && <>error</>}
      <ProjectGrid>
        {data.map((item, index) => (
          <FeaturedProject
            key={"featured-project-" + index}
            name={item.name}
            description={item.minDescription}
            tech={item.tech}
            index={index}
            img={item.img}
            openPage={item.images?.length > 0 ? true : false}
            external={item.external}
            github={item.github}
            id={item.id}
          />
        ))}
      </ProjectGrid>
      {/* <h2 style={{ color: "white", textAlign: "center", margin: "100px" }}>
        Other project
      </h2>
      <RowProjects>
        {projectsList.map((item, index) => (
          <Project
            key={"featured-project-" + index}
            name={item.name}
            description={item.description}
            tech={item.tech}
            index={index}
            external={item.external}
            github={item.github}
          />
        ))}
      </RowProjects> */}
    </Section>
  );
}
const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media only screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;
