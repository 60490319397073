import React from "react";
import Section from "../section";
import ismail from "../../asserts/images/1658937336548.jpeg";
import { GoCode } from "react-icons/go";
import styled from "styled-components";

import {
  getFirestore,
  collection,
  query,
  limit,
  getDocs,
  where,
} from "firebase/firestore";
import { app } from "../../firebase/firebase";
import Hero from "./Hero";

const db = getFirestore(app);
const Ul = styled.ul`
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export default function About() {
  const [data, setData] = React.useState({ aboutme: "" });
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const getLatestAboutme = async () => {
    const aboutmeCollection = collection(db, "aboutme");
    const q = query(
      aboutmeCollection,

      // orderBy("createdAt", "desc"), // Sort by createdAt field in descending order
      limit(1) // Limit the results to the latest 100
    );

    const querySnapshot = await getDocs(q);
    const aboutme = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return aboutme[0];
  };

  React.useEffect(() => {
    getLatestAboutme()
      .then((aboutme) => {
        setData(aboutme);
        setIsLoading(false);
        console.log(aboutme);
      })
      .catch((error) => {
        setError(error.message);
        setIsLoading(false);
      });
  }, []);
  return (
    <>
      <Hero url={data.url} />
      <Section name="About Me" id="about">
        <div className="section-about">
          <div
            style={{ margin: " 0px 20px 20px 0px" }}
            data-aos-duration="1400"
            data-aos="fade-up"
            data-aos-once
          >
            <img src={ismail} alt="an old car" className="img-animation" />
          </div>
          <div
            data-aos-duration="1400"
            data-aos="fade-up"
            style={{ color: "#B2BEE3", fontSize: "18px" }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: data.aboutme || "<p>No description available.</p>",
              }}
            ></div>
            <p style={{ color: "#B2BEE3", fontSize: "18px" }}>
              Here are a few technologies I’ve been working with recently:
            </p>
            <Ul style={{ color: "#B2BEE3", fontSize: "18px" }}>
              <li>
                <GoCode color="#62ECD0" style={{ marginRight: "10px" }} />
                JavaScript
              </li>
              <li>
                {" "}
                <GoCode color="#62ECD0" style={{ marginRight: "10px" }} />
                React
              </li>
              <li>
                {" "}
                <GoCode color="#62ECD0" style={{ marginRight: "10px" }} />
                Node.Js
              </li>
              <li>
                {" "}
                <GoCode color="#62ECD0" style={{ marginRight: "10px" }} />
                TypeScript
              </li>
              <li>
                {" "}
                <GoCode color="#62ECD0" style={{ marginRight: "10px" }} />
                React Native
              </li>
              <li>
                {" "}
                <GoCode color="#62ECD0" style={{ marginRight: "10px" }} />
                GraphQl
              </li>
            </Ul>
          </div>
        </div>
      </Section>
    </>
  );
}
