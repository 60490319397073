import React from "react";
import "./App.css";
import Layout from "./components/layout";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AOS from "aos";
import Navbar from "./components/navbar";
import "aos/dist/aos.css";
import Contact from "./components/sections/Contact";
import Side from "./components/side";
import MenuButton from "./components/menuButton";
import { Container } from "./styles/styleComponent";
import SocialMedia from "./components/socialMedia";

import Home from "./pages/Home.page";
import Project from "./pages/Project.page";

export const initialState = { menu_is_open: false };

function reducer(state, action) {
  switch (action.type) {
    case "OPEN":
      return { menu_is_open: true };
    case "CLOSE":
      return { menu_is_open: false };
    default:
      throw new Error();
  }
}

export const WebSiteContext = React.createContext(initialState);
function App() {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    AOS.init();
  }, []);

  React.useEffect(() => {
    const el = document.getElementById("main-d");
    if (state.menu_is_open) {
      el.classList.add("blur");
    } else {
      el.classList.remove("blur");
    }
  }, [state.menu_is_open]);

  return (
    <WebSiteContext.Provider value={{ state, dispatch }}>
      <Side />
      <MenuButton />
      <SocialMedia />
      <div className="" id="main-d">
        <Router>
          <Navbar />
          <Layout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/:id" element={<Project />} />
            </Routes>

            <div>
              <Container>
                <Contact />
              </Container>
            </div>
          </Layout>
        </Router>
      </div>
    </WebSiteContext.Provider>
  );
}

export default App;
