import React from "react";

import About from "../components/sections/About";

import Projects from "../components/sections/projects";

import "aos/dist/aos.css";

import { Container } from "../styles/styleComponent";

import Highlighted from "../components/sections/Highlighted";
import Articles from "../components/sections/Articles";

function Home() {
  return (
    <div>
      <div>
        <Container>
          <About />
        </Container>
      </div>
      <div>
        <Container>
          <Articles />
        </Container>
      </div>
      <div>
        <Container>
          <Highlighted />
        </Container>
      </div>
      <div>
        <Container>
          <Projects />
        </Container>
      </div>
    </div>
  );
}

export default Home;
