import React from "react";

export default function Section({ name, children, id }) {
  return (
    <section
      data-aos="fade-up"
      data-aos-once
      data-aos-duration="1400"
      id={id}
      style={{ margin: "0px 0px" }}
    >
      <div style={{ padding: "80px 0px" }}>
        <h2 className="section-name">
          <span style={{ color: "#62ECD0" }}>{`< `}</span>
          {name} <span style={{ color: "#62ECD0" }}>{`/>`}</span>
        </h2>
        {children}
      </div>
    </section>
  );
}
